<template>
  <MaintenancePanelMolecule :title="$t('maintenance.hourMeter')" :badge="$t('maintenance.badgeText', { x: badgeCount })">
    <template v-slot:main>
      <HourMeterChartMolecule
        :isLoading="$apollo.loading"
        :hourMeterData="hourMeterData"
        :durationTag="durationTag"
        :engineHoursAssetDimension="engineHoursAssetDimension"
      />
    </template>
  </MaintenancePanelMolecule>
</template>

<script>
import { timeFrameParamGenerator } from '@/utils/maintenance/healthUtils'

import HourMeterChartMolecule from './HourMeterChartMolecule'
import MaintenancePanelMolecule from '@/components/Atomic/Molecules/Maintenance/MaintenancePanelMolecule'

import ASSET_DIMENSION_DATA_QUERY from '#/graphql/assetDimensions/data.gql'
import ASSET_DIMENSION_QUERY from '#/graphql/assetDimensions/assetDimension.gql'

export default {
  inject: ['theme'],
  props: {
    assetId: {
      type: String,
      required: true,
    },
    durationTag: {
      type: String,
      required: true,
    },
  },
  components: {
    MaintenancePanelMolecule,
    HourMeterChartMolecule,
  },
  data() {
    return {
      hourMeterData: null,
      engineHoursAssetDimension: null,
    }
  },
  computed: {
    timeFrame() {
      return timeFrameParamGenerator(this.durationTag)
    },
    badgeCount() {
      return this.timeFrame.noOfDays
    },
    selectedTimeframe() {
      if (!this.timeFrame) {
        return null
      }
      return {
        start: this.timeFrame.start,
        end: this.timeFrame.end,
        timezone: this.timeFrame.timezone,
        granularity: this.timeFrame.granularity,
        shifts: [],
      }
    },
  },
  apollo: {
    engineHoursAssetDimension: {
      query: ASSET_DIMENSION_QUERY,
      variables() {
        return {
          where: {
            name: 'engine_hours',
          },
        }
      },
      update: data => {
        return data.assetDimension
      },
    },
    hourMeterData: {
      query: ASSET_DIMENSION_DATA_QUERY,
      variables() {
        return {
          where: {
            assetDimension: {
              name: 'engine_hours',
            },
            timeframe: this.selectedTimeframe,
            assets: {
              id_in: [this.assetId],
            },
          },
        }
      },
      skip() {
        return !this.selectedTimeframe
      },
      update(data) {
        return data.assetDimensionData
      },
    },
  },
}
</script>
