<template>
  <AssetSeverityWrapperStyled>
    <AssetSeverityStyled>
      <SeverityLampsMolecule :severities="cell.severities" :activeIssues="cell.activeIssues" />
    </AssetSeverityStyled>
  </AssetSeverityWrapperStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import SeverityLampsMolecule from '@/components/Atomic/Molecules/SeverityLampsMolecule'

const AssetSeverityWrapperStyled = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding-left: 0.5rem;
  @media (min-width: 1560px) {
    padding-left: 2rem;
  }
`
const AssetSeverityStyled = styled('div')`
  display: flex;
  flex-direction: row;
  border-radius: 8px 0px 0px 8px;
  height: 40px;
  width: fit-content;
  padding: 0px 12px;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`

export default {
  components: {
    AssetSeverityWrapperStyled,
    AssetSeverityStyled,
    SeverityLampsMolecule,
  },
  props: {
    cell: {
      type: Object,
      required: true,
    },
  },
  computed: {},
}
</script>
