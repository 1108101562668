<template>
  <ScatterChartStyled>
    <VChart
      ref="scatterChart"
      :option="option"
      :updateOptions="{ notMerge: true }"
      @mouseover="addTooltip($event)"
      @mouseout="removeTooltip()"
      autoresize
    />
  </ScatterChartStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { ScatterChart, LineChart } from 'echarts/charts'
import VChart from 'vue-echarts'
import chartAxisLabelMixin from '@/mixins/chartAxisLabel'

import { TooltipComponent, LegendComponent, DataZoomComponent } from 'echarts/components'

use([CanvasRenderer, ScatterChart, TooltipComponent, LegendComponent, LineChart, DataZoomComponent])

const ScatterChartStyled = styled('div')`
  display: flex;
  position: relative;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export default {
  inject: ['theme'],
  mixins: [chartAxisLabelMixin],
  props: {
    option: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
    },
    customToolTipText: {
      type: Array,
    },
  },
  components: {
    ScatterChartStyled,
    VChart,
  },
  watch: {
    isLoading: {
      handler() {
        this.handleLoadingState()
      },
      immediate: true,
    },
  },
  mounted() {
    this.handleLoadingState()
  },
  methods: {
    handleLoadingState() {
      const isLoading = this.isLoading
      if (this.isLoading === undefined) {
        return
      }
      const eChart = this.$refs.chart?.chart
      if (eChart) {
        if (isLoading) {
          eChart.showLoading()
        } else {
          eChart.hideLoading()
        }
      }
    },
    addTooltip(event) {
      const chartRef = this.$refs.scatterChart
      if (event.componentType === 'yAxis') {
        const found = this.customToolTipText?.find(e => e.code === event.value)
        const customToolTipText = found?.label || null
        this.appendTooltip(chartRef, event, customToolTipText)
      } else {
        this.appendTooltip(chartRef, event)
      }
    },
    removeTooltip() {
      this.clearTooltip()
    },
  },
}
</script>
