<template>
  <SeverityLampsStyled>
    <SeverityLampAtom :class="{ priority: priorityEnabled, active: activeIssues && activeIssues.includes('Priority') }" />
    <SeverityLampAtom :class="{ red: errorEnabled, active: activeIssues && activeIssues.includes('Red') }" />
    <SeverityLampAtom :class="{ amber: warningEnabled, active: activeIssues && activeIssues.includes('Yellow') }" />
    <SeverityLampAtom :class="{ malfunction: malfunctionEnabled, active: activeIssues && activeIssues.includes('Malfunction') }" />
    <SeverityLampAtom :class="{ protection: protectionEnabled, active: activeIssues && activeIssues.includes('Protection') }" />
  </SeverityLampsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import SeverityLampAtom from '@/components/Atomic/Atoms/SeverityLampAtom'

const SeverityLampsStyled = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 10px;
`

export default {
  components: {
    SeverityLampsStyled,
    SeverityLampAtom,
  },
  props: {
    severities: {
      type: Array,
    },
    activeIssues: {
      type: Array,
    },
  },
  computed: {
    priorityEnabled() {
      return this.severities.includes('Priority')
    },
    errorEnabled() {
      return this.severities.includes('Red')
    },
    warningEnabled() {
      return this.severities.includes('Yellow')
    },
    malfunctionEnabled() {
      return this.severities.includes('Malfunction')
    },
    protectionEnabled() {
      return this.severities.includes('Protection')
    },
  },
}
</script>
