var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('InsightsByDurationOrganismStyled', [_c('TableV2', {
    attrs: {
      "title": _vm.$t('maintenance.insightsByDuration'),
      "noTitleCapitalize": true,
      "headers": _vm.headers,
      "rows": _vm.rows,
      "sorting": _vm.sorting,
      "filtersAvailable": _vm.filtersAvailable,
      "filterOptionsSelected": _vm.filterOptionsSelected,
      "isLoading": _vm.$apollo.loading,
      "searchQuery": _vm.searchQuery,
      "enableSearch": true,
      "isMobile": _vm.isMobile,
      "messages": _vm.messages,
      "isRowSelectable": false,
      "badgeText": _vm.$t('maintenance.badgeText', {
        x: _vm.badgeCount
      })
    },
    on: {
      "update:sorting": function updateSorting($event) {
        _vm.sorting = $event;
      },
      "setSelectedFilterOption": _vm.setSelectedFilterOption,
      "resetFilters": _vm.resetFilters,
      "textFilter": _vm.textSearch
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }