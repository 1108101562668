<template>
  <AssetVariationWrapperStyled>
    <div :class="classGenerator"></div>
    <div class="label">{{ cell.value }} {{ cell.label }}</div>
  </AssetVariationWrapperStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const AssetVariationWrapperStyled = styled('div')`
  display: grid;
  grid-template-columns: 1.5rem 1fr;
  gap: 0.5rem;
  padding-left: 3rem;

  > .label {
    justify-items: right;
    color: ${props => props.theme.colors.muted};
    font-size: 14px;
  }
  > .increase {
    justify-self: center;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top: 0;
    border-bottom: 10px solid ${props => props.theme.colors.atomic.severityRed};
    align-self: center;
  }
  > .decrease {
    justify-self: center;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom: 0;
    border-top: 10px solid ${props => props.theme.colors.atomic.statusGreen};
    align-self: center;
  }
  > .square {
    justify-self: center;
    width: 16px;
    height: 16px;
    background: ${props => props.theme.colors.muted};
    border: none;
    border-radius: 0px;
    align-self: center;
  }
`

export default {
  components: {
    AssetVariationWrapperStyled,
  },
  props: {
    cell: {
      type: Object,
      required: true,
    },
  },
  computed: {
    classGenerator() {
      return [
        { increase: this.cell.value > 0 },
        { decrease: this.cell.value < 0 },
        { square: this.cell.value == 0 || this.cell.value === '<0.01' },
      ]
    },
  },
}
</script>
