<template>
  <InsightsByDurationOrganismStyled>
    <TableV2
      :title="$t('maintenance.insightsByDuration')"
      :noTitleCapitalize="true"
      :headers="headers"
      :rows="rows"
      :sorting.sync="sorting"
      :filtersAvailable="filtersAvailable"
      :filterOptionsSelected="filterOptionsSelected"
      :isLoading="$apollo.loading"
      :searchQuery="searchQuery"
      :enableSearch="true"
      :isMobile="isMobile"
      :messages="messages"
      :isRowSelectable="false"
      :badgeText="$t('maintenance.badgeText', { x: badgeCount })"
      @setSelectedFilterOption="setSelectedFilterOption"
      @resetFilters="resetFilters"
      @textFilter="textSearch"
    />
  </InsightsByDurationOrganismStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { get, partition, concat, orderBy, uniqBy, groupBy, compact, maxBy } from 'lodash'
import { DateTime } from 'luxon'
import { TABLE_SORT_TYPES } from '@/constants/settings'
import resizeMixin from '@/mixins/resize'
import localesMixin from '@/mixins/locales'

import TableV2 from '@/components/Atomic/Molecules/TableV2.vue'
import AssetHealthTableSeverityMolecule from '@/components/Atomic/Molecules/AssetHealthTableSeverityMolecule'
import AssetHealthTableCountMolecule from './AssetHealthTableCountMolecule'
import AssetHealthTableVariationAtom from './AssetHealthTableVariationAtom'
import TableTextCell from '@/components/Atomic/Atoms/TableCells/TableTextCell.vue'

import { timeFrameParamGenerator } from '@/utils/maintenance/healthUtils'

import ASSET_ISSUES_BY_DURATION_QUERY from '#/graphql/assetDimensions/assetIssuesDuration.gql'

const InsightsByDurationOrganismStyled = styled('div')`
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.navFontNormal};
`

export default {
  inject: ['uiSettings'],
  mixins: [resizeMixin, localesMixin],
  props: {
    assetId: {
      type: String,
      required: true,
    },
    durationTag: {
      type: String,
      required: true,
    },
  },
  components: {
    InsightsByDurationOrganismStyled,
    TableV2,
  },
  data() {
    return {
      isMobile: true,
      searchQuery: '',
      sorting: {
        key: 'duration',
        asc: false,
      },
      filterOptionsSelected: [],
      assetIssuesDuration: [],
    }
  },
  computed: {
    timeFrame() {
      return timeFrameParamGenerator(this.durationTag)
    },
    badgeCount() {
      return this.timeFrame.noOfDays
    },
    selectedTimeframe() {
      if (!this.timeFrame) {
        return null
      }
      return {
        start: this.timeFrame.start,
        end: this.timeFrame.end,
        timezone: this.timeFrame.timezone,
        granularity: this.timeFrame.granularity,
        shifts: [],
      }
    },
    headers() {
      const headers = [
        {
          id: `header_code`,
          label: this.$tc('code', 1),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'code',
          size: 'xsmall',
        },
        {
          id: `header_label`,
          label: this.$tc('label', 1),
          sortType: TABLE_SORT_TYPES.A_TO_Z,
          isSortable: true,
          sortKey: 'label',
          size: 'large',
        },
        {
          id: `header_type`,
          label: this.$tc('type', 1),
          sortType: TABLE_SORT_TYPES.A_TO_Z,
          isSortable: true,
          sortKey: 'type',
          size: 'xsmall',
        },
        {
          id: `header_severity`,
          label: this.$tc('severity', 1),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'mappedSeverity.id',
          size: 'medium',
        },
        {
          id: `header_duration`,
          label: this.$t('maintenance.duration'),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'duration',
          size: 'large',
        },
        {
          id: `header_variation`,
          label: this.$t('maintenance.variation'),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'variation',
          size: 'medium',
        },
      ]
      return headers
    },
    uniqueErrorTypes() {
      return uniqBy(this.mappedAssetIssues, 'type').map(assetIssue => {
        return { name: assetIssue.type, __typename: 'Type' }
      })
    },

    uniqueCodes() {
      const codes = uniqBy(this.mappedAssetIssues, 'code').map(assetIssue => {
        return { name: assetIssue.code, __typename: 'Code' }
      })
      return orderBy(codes, 'name')
    },

    uniqueSeverities() {
      return uniqBy(this.mappedAssetIssues, 'highestSeverity').map(assetIssue => {
        return { name: assetIssue.highestSeverity, __typename: 'Severity' }
      })
    },
    filtersAvailable() {
      const filters = [
        {
          id: 'filter_code',
          class: 'filter filter-code',
          modelName: 'code',
          placeholder: this.$tc('code', 2),
          searchable: false,
          options: this.uniqueCodes
            .filter(f => f.name !== '0')
            .map(m => {
              return {
                ...m,
                id: m.name,
                label: m.name,
              }
            }),
          value: this.filterOptionsSelected.filter(f => f.__typename === 'Code'),
          isMultiple: true,
        },
        {
          id: 'filter_error_type',
          class: 'filter filter-error-type',
          modelName: 'type',
          placeholder: this.$tc('type', 2),
          searchable: false,
          options: this.uniqueErrorTypes.map(m => ({
            ...m,
            id: m.name,
            label: m.name,
          })),
          value: this.filterOptionsSelected.filter(f => f.__typename === 'Type'),
          isMultiple: true,
        },
        {
          id: 'filter_severity',
          class: 'filter filter-severity',
          modelName: 'severity',
          placeholder: this.$tc('severity', 2),
          searchable: false,
          options: this.uniqueSeverities.map(m => ({
            ...m,
            id: m.name,
            label: this.$t(`severityCodes.${m?.name.toLowerCase()}`),
          })),
          value: this.filterOptionsSelected.filter(f => f.__typename === 'Severity'),
          isMultiple: true,
        },
      ]
      return filters
    },
    mappedAssetIssues() {
      return this.assetIssuesDuration.map(issue => {
        const severityOrderMap = [
          { value: 'Protection', id: 1 },
          { value: 'Malfunction', id: 2 },
          { value: 'Yellow', id: 3 },
          { value: 'Red', id: 4 },
          { value: 'Priority', id: 5 },
        ]
        const severity = severityOrderMap.find(sev => sev.value === issue.highestSeverity)
        const mappedSeverity = {
          id: severity.id,
          code: severity.value,
        }
        return { ...issue, type: issue.type.replace('_NEW', ''), mappedSeverity: { ...mappedSeverity } }
      })
    },
    assetIssuesFiltered() {
      if (this.filterOptionsSelected.length > 0) {
        return this.mappedAssetIssues.reduce((assetIssuesFiltered, assetIssue) => {
          const filterGroups = groupBy(this.filterOptionsSelected, '__typename')
          const notInAllGroups = Object.keys(filterGroups).some(key => {
            const filterGroup = filterGroups[key]
            let assetPath
            let filterPath
            if (key === 'Type') {
              assetPath = 'type'
              filterPath = 'id'
            } else if (key === 'Code') {
              assetPath = 'code'
              filterPath = 'id'
            } else if (key === 'Severity') {
              assetPath = 'highestSeverity'
              filterPath = 'id'
            } else {
              throw new Error(`unhandled filter type ${key}`)
            }

            const found = filterGroup.find(filter => get(filter, filterPath) === get(assetIssue, assetPath))
            return !found
          })
          if (!notInAllGroups) {
            assetIssuesFiltered.push(assetIssue)
          }
          return assetIssuesFiltered
        }, [])
      }
      return this.mappedAssetIssues
    },
    assetIssuesSorted() {
      const partitions = partition(this.assetIssuesFiltered, x => !!get(x, this.sorting.key, null))
      const sortDirection = this.sorting.key === 'variation' ? (this.sorting.asc ? 'desc' : 'asc') : this.sorting.asc ? 'asc' : 'desc'
      return concat(orderBy(partitions[0], [this.sorting.key], [sortDirection]), partitions[1])
    },
    maxCount() {
      return maxBy(this.mappedAssetIssues, 'duration')?.duration ?? null
    },
    locale() {
      const ds = get(this.uiSettings, 'dates', 'DE_DE')
      return ds.replace('_', '-').toLowerCase()
    },
    lastAnalysis() {
      if (this.selectedTimeframe?.start === null) {
        return '-'
      } else {
        const localTime = DateTime.fromISO(this.selectedTimeframe.start).setLocale(this.locale)
        return `${localTime.toLocaleString(DateTime.DATE_SHORT)}`
      }
    },
    rows() {
      const rows = this.assetIssuesSorted.map((assetIssue, key) => {
        const code = assetIssue?.code
        const type = assetIssue.type === null ? '-' : assetIssue.type
        const variation = assetIssue?.variation === 0 ? 0 : this.convUnit(assetIssue?.variation, 's', 'h', 2, false, false)
        const label = assetIssue?.label
        const cells = [
          {
            id: `${key}_code`,
            sortableValue: code,
            isMobilePrimarycol: true,
            mobilePrimarycolLabel: type,
            headerId: `header_code`,
          },
          {
            id: `${key}_label`,
            component: TableTextCell,
            label: label,
            sortableValue: label,
            headerId: `header_label`,
          },
          {
            id: `${key}_type`,
            sortableValue: type,
            headerId: 'header_type',
          },
          {
            id: `${key}_severity`,
            component: AssetHealthTableSeverityMolecule,
            severities: [assetIssue?.mappedSeverity.code],
            sortableValue: assetIssue?.mappedSeverity.id,
            headerId: `header_severity`,
          },
          {
            id: `${key}_duration`,
            component: AssetHealthTableCountMolecule,
            percent: (assetIssue?.duration / this.maxCount) * 100,
            count: assetIssue?.duration !== null ? this.convUnit(assetIssue?.duration, 's', 'h', 2, true, true) : '- h',
            severity: assetIssue?.highestSeverity.toLowerCase(),
            sortableValue: assetIssue?.duration,
            headerId: `header_duration`,
          },
          {
            id: `${key}_variation`,
            component: AssetHealthTableVariationAtom,
            value: variation > 0 ? `+${variation}` : `${variation}`,
            label: `${this.$tc('maintenance.since')} ${this.lastAnalysis}`,
            sortableValue: assetIssue?.variation,
            headerId: `header_variation`,
          },
        ]
        return {
          rowId: `row_${key}`,
          cells,
        }
      })
      const filteredTable = rows.filter(row => {
        const props = compact(row.cells.map(item => item.sortableValue))
        return props.some(
          prop =>
            !this.searchQuery ||
            (typeof prop === 'string'
              ? prop.toLowerCase().includes(this.searchQuery.toLowerCase())
              : prop.toString(10).toLowerCase().includes(this.searchQuery)),
        )
      })
      return filteredTable
    },
    messages() {
      let message = ''
      if (this.rows.length === 0) {
        message =
          this.searchQuery === ''
            ? this.$tc('messages.noDataForSelectedTime')
            : this.$t('messages.searchNoResults', { query: this.searchQuery })
      }
      return message
    },
  },
  methods: {
    gridItemResized() {
      this.handleResize()
    },
    handleResize() {
      this.$nextTick(() => {
        const size = get(this.$el, 'clientWidth', 96)
        const isMobile = size < 600 && size >= 96
        this.isMobile = isMobile || (this.isPreview && this.isMobilePreview)
      })
    },
    textSearch(query) {
      this.searchQuery = query
    },
    setSelectedFilterOption(filter) {
      const found = this.filterOptionsSelected.find(f => f.id === filter.id)
      if (found) {
        this.filterOptionsSelected = this.filterOptionsSelected.filter(f => f.id !== filter.id)
      } else {
        this.filterOptionsSelected.push(filter)
      }
    },
    resetFilters() {
      this.filterOptionsSelected = []
    },
  },
  apollo: {
    assetIssuesDuration: {
      query: ASSET_ISSUES_BY_DURATION_QUERY,
      variables() {
        return {
          where: {
            timeframe: this.selectedTimeframe,
            assetIds: [this.assetId],
            groupBy: ['CODE', 'TYPE', 'HIGHEST_SEVERITY', 'SEVERITY', 'LABEL'],
          },
        }
      },
      skip() {
        return !this.selectedTimeframe
      },
    },
  },
}
</script>
