<template>
  <MaintenancePanelMolecule :title="$t('maintenance.assetActivity')" :badge="$t('maintenance.badgeText', { x: badgeCount })">
    <template v-slot:main>
      <AssetActivityMolecule :isLoading="$apollo.loading" :activityDataSet="activityDataSet" />
    </template>
  </MaintenancePanelMolecule>
</template>

<script>
import { timeFrameParamGenerator } from '@/utils/maintenance/healthUtils'

import AssetActivityMolecule from '@/components/Atomic/Molecules/AssetActivityMolecule.vue'
import MaintenancePanelMolecule from '@/components/Atomic/Molecules/Maintenance/MaintenancePanelMolecule'

import ASSET_DIMENSION_DATA_QUERY from '#/graphql/assetDimensions/data.gql'
import ASSET_DIMENSIONS_QUERY from '#/graphql/assetDimensions/assetDimensions.gql'

export default {
  inject: ['theme'],
  props: {
    assetId: {
      type: String,
      required: true,
    },
    durationTag: {
      type: String,
      required: true,
    },
  },
  components: {
    MaintenancePanelMolecule,
    AssetActivityMolecule,
  },
  data() {
    return {
      assetDimensions: [],
      idleTimeData: null,
      workingTimeData: null,
    }
  },
  computed: {
    timeFrame() {
      return timeFrameParamGenerator(this.durationTag)
    },
    badgeCount() {
      return this.timeFrame.noOfDays
    },
    selectedTimeframe() {
      if (!this.timeFrame) {
        return null
      }
      return {
        start: this.timeFrame.start,
        end: this.timeFrame.end,
        timezone: this.timeFrame.timezone,
        granularity: this.timeFrame.granularity,
        shifts: [],
      }
    },
    activityDataSet() {
      return this.assetDimensions
        .filter(n => n.name !== 'engine_hours')
        .map(assetDimension => {
          if (assetDimension.name === 'working_time') {
            return {
              ...assetDimension,
              ...this.workingTimeData,
            }
          } else if (assetDimension.name === 'idle_time') {
            return {
              ...assetDimension,
              ...this.idleTimeData,
            }
          }
        })
    },
  },
  apollo: {
    assetDimensions: {
      query: ASSET_DIMENSIONS_QUERY,
      variables() {
        return {
          where: {
            name_in: ['working_time', 'idle_time'],
          },
        }
      },
    },
    idleTimeData: {
      query: ASSET_DIMENSION_DATA_QUERY,
      variables() {
        return {
          where: {
            assetDimension: {
              name: 'idle_time',
            },
            timeframe: this.selectedTimeframe,
            assets: {
              id_in: [this.assetId],
            },
          },
        }
      },
      skip() {
        return !this.selectedTimeframe
      },
      update(data) {
        return data.assetDimensionData
      },
    },
    workingTimeData: {
      query: ASSET_DIMENSION_DATA_QUERY,
      variables() {
        return {
          where: {
            assetDimension: {
              name: 'working_time',
            },
            timeframe: this.selectedTimeframe,
            assets: {
              id_in: [this.assetId],
            },
          },
        }
      },
      skip() {
        return !this.selectedTimeframe
      },
      update(data) {
        return data.assetDimensionData
      },
    },
  },
}
</script>
