<template>
  <LineChart :isLoading="isLoading" :option="option" />
</template>

<script>
import LineChart from '@/components/Atomic/Atoms/LineChart'
import localesMixin from '@/mixins/locales'
import { DateTime, Interval } from 'luxon'
import { timeFrameParamGenerator } from '@/utils/maintenance/healthUtils'
import units from '@/utils/units'
import { getAssetDimensionNameByLocale } from '@common/utils/src'

export default {
  inject: ['theme', 'uiSettings'],
  mixins: [localesMixin],
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    durationTag: {
      type: String,
      required: true,
    },
    hourMeterData: {
      type: Object,
    },
    engineHoursAssetDimension: {
      type: Object,
    },
  },
  components: {
    LineChart,
  },
  computed: {
    locale() {
      return (this.uiSettings?.dates ?? 'DE_DE').toLowerCase().replace('_', '-')
    },
    assetDimensionName() {
      return this.engineHoursAssetDimension?.name || null
    },
    selectedNoOfDays() {
      const timeFrame = timeFrameParamGenerator(this.durationTag)
      return timeFrame.noOfDays
    },
    xAxis() {
      return {
        type: 'category',
        data: this.hourMeterXaxisData.map(d => d.label),
        splitLine: {
          show: false,
        },
        axisLabel: {
          color: this.theme.colors.textActivePrimary,
          align: 'center',
        },
        axisTick: {
          show: true,
          alignWithLabel: false,
        },
      }
    },
    yAxis() {
      return {
        type: 'value',
        min: this.hourMeterSeriesSorted,
        splitLine: {
          show: true,
          lineStyle: {
            type: 'dotted',
            color: this.theme.colors.atomic.mildgrey,
          },
        },
      }
    },
    series() {
      const kpiLocale = this.locale.toUpperCase().slice(0, 2)

      if (!this.assetDimensionName || !this.hourMeterData) {
        return []
      }
      return [
        {
          name: this.engineHoursAssetDimension?.nameTranslations
            ? getAssetDimensionNameByLocale(this.engineHoursAssetDimension?.nameTranslations, kpiLocale)
            : this.assetDimensionName,
          data: this.hourMeterSeries,
          type: 'line',
          step: 'middle',
          symbol: 'none',
        },
      ]
    },
    hourMeterXaxisData() {
      let days = []
      for (var i = 0; i < this.selectedNoOfDays; i++) {
        const interval = Interval.fromDateTimes(
          DateTime.local().minus({ day: i }).startOf('day'),
          DateTime.local().minus({ day: i }).endOf('day'),
        )
        days.push({ interval, label: interval.start.setLocale(this.locale).toLocaleString({ day: 'numeric', month: 'short' }) })
      }
      return days.reverse()
    },
    hourMeter() {
      return this.hourMeterData?.aggrByTimebuckets ?? []
    },
    hourMeterSeriesSorted() {
      return this.hourMeterSeries.length < 1 ? 0 : [...this.hourMeterSeries].sort()[0]
    },
    hourMeterSeries() {
      const hourMeterToDay = this.hourMeterXaxisData.reduce((acc, day) => {
        const hourMeterOfDay = this.hourMeter.find(data => day.interval.contains(DateTime.fromISO(data.time)))
        if (!hourMeterOfDay) {
          acc.push(0)
        } else {
          const unitSI = this.engineHoursAssetDimension ? this.engineHoursAssetDimension.physicalUnitSI : null
          const unitUIMetric = this.engineHoursAssetDimension ? this.engineHoursAssetDimension.physicalUnitUIMetric : null
          const convertedValue = units(hourMeterOfDay.floatValue, unitSI, unitUIMetric, 2, false, false, false, null, null, true)
          acc.push(convertedValue)
        }
        return acc
      }, [])
      return hourMeterToDay
    },
    option() {
      return {
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        series: this.series,
      }
    },
  },
}
</script>
