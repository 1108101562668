<template>
  <PieChartStyled>
    <VChart ref="chart" :option="optionMapped" :updateOptions="{ notMerge: true }" autoresize />
  </PieChartStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { PieChart } from 'echarts/charts'
import VChart from 'vue-echarts'

import { TooltipComponent, LegendComponent } from 'echarts/components'

use([CanvasRenderer, PieChart, TooltipComponent, LegendComponent])

const PieChartStyled = styled('div')`
  display: flex;
  position: relative;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export default {
  inject: ['theme'],
  props: {
    option: {
      type: Object,
      required: true,
    },
    displayLegend: {
      type: Boolean,
      required: false,
      default: true,
    },
    isLoading: {
      type: Boolean,
    },
  },
  components: {
    PieChartStyled,
    VChart,
  },
  watch: {
    isLoading: {
      handler() {
        this.handleLoadingState()
      },
      immediate: true,
    },
  },
  computed: {
    optionMapped() {
      if (!this.displayLegend) {
        return this.option
      }
      const option = { ...this.option }
      option.legend.textStyle.color = this.theme.colors.textActivePrimary
      return option
    },
  },
  mounted() {
    this.handleLoadingState()
  },
  methods: {
    handleLoadingState() {
      const isLoading = this.isLoading
      if (this.isLoading === undefined) {
        return
      }
      const eChart = this.$refs.chart?.chart
      if (eChart) {
        if (isLoading) {
          eChart.showLoading()
        } else {
          eChart.hideLoading()
        }
      }
    },
  },
}
</script>
