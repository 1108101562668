var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetVariationWrapperStyled', [_c('div', {
    class: _vm.classGenerator
  }), _c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.cell.value) + " " + _vm._s(_vm.cell.label))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }