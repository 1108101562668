<template>
  <ChartInteractionWrapperStyled v-away="away">
    <slot />
    <ChartInteractionBlockerStyled :class="{ block: blockInteraction }" @click="blockInteraction = false">
      <strong>
        {{ $t('messages.clickToInteract') }}
      </strong>
    </ChartInteractionBlockerStyled>
  </ChartInteractionWrapperStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { directive as onClickaway } from 'vue-clickaway'

const ChartInteractionWrapperStyled = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const ChartInteractionBlockerStyled = styled('div')`
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  > strong {
    display: none;
  }
  &.block {
    display: flex;
    cursor: pointer;
  }
  &:hover {
    background-color: ${({ theme }) =>
      chroma(theme.isDark ? theme.colors.atomic.healthStatusIconBG : theme.colors.whiteSmokeDark)
        .alpha(0.5)
        .css()};

    > strong {
      display: inline;
    }
  }
`

export default {
  components: {
    ChartInteractionWrapperStyled,
    ChartInteractionBlockerStyled,
  },
  directives: {
    away: onClickaway,
  },
  data() {
    return {
      blockInteraction: true,
    }
  },
  methods: {
    away() {
      this.blockInteraction = true
    },
  },
}
</script>
