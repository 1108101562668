<template>
  <AssetHealthStatisticsStyled>
    <div class="group span-2">
      <ClickableCardsOrganism :assetId="this.$route?.params?.id" :selectedCardId="this.selectedCardId" :defaultCardId="defaultCardId" />
      <InsightsBySeverityOrganism class="span-2" :assetId="this.$route?.params?.id" :durationTag="this.selectedCardId" />
    </div>
    <HourMeterOrganism :assetId="this.$route?.params?.id" :durationTag="this.selectedCardId" />
    <AssetActivityOrganism :assetId="this.$route?.params?.id" :durationTag="this.selectedCardId" />
    <InsightsByOccurrenceOrganism class="span-2" :assetId="this.$route?.params?.id" :durationTag="this.selectedCardId" />
    <InsightsByCountOrganism class="span-2" :assetId="this.$route?.params?.id" :durationTag="this.selectedCardId" />
    <InsightsByDurationOrganism class="span-2" :assetId="this.$route?.params?.id" :durationTag="this.selectedCardId" />
  </AssetHealthStatisticsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import ClickableCardsOrganism from '../../SharedPages/AssetHealth/AssetHealthStatistics/ClickableCardsOrganism'
import InsightsBySeverityOrganism from '../../SharedPages/AssetHealth/AssetHealthStatistics/InsightsBySeverityOrganism'
import HourMeterOrganism from '../../SharedPages/AssetHealth/AssetHealthStatistics/HourMeterOrganism'
import AssetActivityOrganism from '../../SharedPages/AssetHealth/AssetHealthStatistics/AssetActivityOrganism'
import InsightsByOccurrenceOrganism from '../../SharedPages/AssetHealth/AssetHealthStatistics/InsightsByOccurrenceOrganism'
import InsightsByCountOrganism from '../../SharedPages/AssetHealth/AssetHealthStatistics/InsightsByCountOrganism'
import InsightsByDurationOrganism from '../../SharedPages/AssetHealth/AssetHealthStatistics/InsightsByDurationOrganism'

const AssetHealthStatisticsStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 38.5rem 20rem 40rem 20rem 20rem;
  grid-gap: 1rem;
  > .span-2 {
    grid-column: span 2;
  }
  > .group {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 8.5rem 30rem;
  }
`

export default {
  components: {
    AssetHealthStatisticsStyled,
    ClickableCardsOrganism,
    InsightsBySeverityOrganism,
    HourMeterOrganism,
    AssetActivityOrganism,
    InsightsByOccurrenceOrganism,
    InsightsByCountOrganism,
    InsightsByDurationOrganism,
  },
  data() {
    return {
      selectedCardId: 'last7Days',
      defaultCardId: 'last7Days',
    }
  },
  watch: {
    $route: {
      handler(route) {
        this.selectedCardId = route?.query?.duration || 'last7Days'
      },
      immediate: true,
    },
  },
}
</script>
