<template>
  <MaintenancePanelMoleculeStyled>
    <header>
      <slot v-if="hasHeaderSlot" name="header" />
      <template v-else>
        <span class="title">{{ title }}</span>
        <BadgeAtom v-if="badge" :label="badge" />
      </template>
    </header>
    <main>
      <slot name="main" />
    </main>
    <footer v-if="hasFooterSlot">
      <slot name="footer" />
    </footer>
  </MaintenancePanelMoleculeStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import BadgeAtom from '@/components/Atomic/Atoms/BadgeAtom'

const MaintenancePanelMoleculeStyled = styled('div')`
  background: ${({ theme }) => theme.colors.atomic.tableBG};
  border-radius: 0.5rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr min-content;
  > header {
    display: flex;
    border-bottom: solid 2px ${p => p.theme.colors.atomic.tableColumnHeaderBG};
    align-items: center;
    padding: 0.5rem;
    gap: 0.5rem;

    > .title {
      color: ${({ theme }) => theme.colors.atomic.textSecondary};
      padding: 0.5rem;
      line-height: 20px;
      font-size: 20px;
      font-weight: 400;

      @media (max-width: 425px) {
        font-size: 18px;
      }
    }
  }
`

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    badge: {
      type: String,
    },
  },
  components: {
    MaintenancePanelMoleculeStyled,
    BadgeAtom,
  },
  computed: {
    hasHeaderSlot() {
      return !!this.$slots.header
    },
    hasFooterSlot() {
      return !!this.$slots.footer
    },
  },
}
</script>
