<template>
  <ClickableCardsMolecule :cards="cards" :isLoading="$apollo.queries.assetIssueCounts.loading" />
</template>

<script>
import ClickableCardsMolecule from '@/components/Atomic/Molecules/ClickableCardsMolecule'

import ASSET_ISSUE_COUNTS_QUERIES from '#/graphql/operations/maintenance/statistics/assetIssueCountsQueries.gql'
import { getLast30DaysInterval, getLast21DaysInterval, getLast14DaysInterval, getLast7DaysInterval } from '../../../../utils/time'
import { DateTime } from 'luxon'

export default {
  props: {
    assetId: {
      type: String,
      required: true,
    },
    selectedCardId: {
      type: String,
      required: true,
    },
    defaultCardId: {
      type: String,
      required: true,
    },
  },
  components: {
    ClickableCardsMolecule,
  },
  data() {
    return {
      assetIssueCounts: null,
      isLoading: true,
    }
  },
  computed: {
    cards() {
      return ['last7Days', 'last14Days', 'last21Days', 'last30Days'].map(id => {
        const count = this.assetIssueCounts?.[id]?.[0]?.count ?? 0
        return {
          id,
          label: this.$t('maintenance.insights'),
          badge: this.$t('maintenance.' + id),
          count,
          isActive: this.selectedCardId === id,
          disabled: count < 1,
          to: {
            query: {
              duration: id === this.defaultCardId ? undefined : id,
            },
          },
        }
      })
    },
  },
  methods: {},
  apollo: {
    assetIssueCounts: {
      query: ASSET_ISSUE_COUNTS_QUERIES,
      variables() {
        return {
          assetId: this.assetId,
          startLast30Days: getLast30DaysInterval().start.toISO(),
          endLast30Days: getLast30DaysInterval().end.toISO(),
          startLast21Days: getLast21DaysInterval().start.toISO(),
          endLast21Days: getLast21DaysInterval().end.toISO(),
          startLast14Days: getLast14DaysInterval().start.toISO(),
          endLast14Days: getLast14DaysInterval().end.toISO(),
          startLast7Days: getLast7DaysInterval().start.toISO(),
          endLast7Days: getLast7DaysInterval().end.toISO(),
          timezone: DateTime.local().zoneName,
          granularity: 'P1D',
        }
      },
      skip() {
        return !this.assetId
      },
      update: data => data,
    },
  },
}
</script>
