<template>
  <AssetCountWrapperStyled>
    <template v-if="cell.count !== '-' || cell.count !== '- h'">
      <div>
        <CountBarStyled :class="classGenerator" :percent="cell.percent" />
      </div>
      <span class="count">{{ cell.count }} </span>
    </template>
    <span v-else>{{ cell.count }}</span>
  </AssetCountWrapperStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const AssetCountWrapperStyled = styled('div')`
  display: grid;
  grid-template-columns: 15rem 1fr;
  align-items: center;
  margin-left: 3rem;
  grid-gap: 1rem;
  > div {
    background: ${({ theme }) => theme.colors.atomic.tableTotal};
  }
  > .count {
    justify-self: right;
  }
`
const CountBarStyled = styled('div')`
  width: ${props => (props.percent ? props.percent : 0)}%;
  background: blue;
  height: 1rem;
  align-items: center;
  &.priority {
    background: ${({ theme }) => theme.colors.atomic.severityPriority};
  }
  &.error {
    background: ${({ theme }) => theme.colors.atomic.severityRed};
  }
  &.warning {
    background: ${({ theme }) => theme.colors.atomic.severityAmber};
  }
  &.malfunction {
    background: ${({ theme }) => theme.colors.atomic.severityMalfunction};
  }
  &.protection {
    background: ${({ theme }) => theme.colors.atomic.severityProtection};
  }
  &.green {
    background: ${({ theme }) => theme.colors.atomic.statusGreen};
  }
`

export default {
  components: {
    AssetCountWrapperStyled,
    CountBarStyled,
  },
  props: {
    cell: {
      type: Object,
      required: true,
    },
  },
  computed: {
    classGenerator() {
      return [
        { malfunction: this.cell.severity === 'malfunction' },
        { priority: this.cell.severity === 'priority' },
        { warning: this.cell.severity === 'yellow' },
        { error: this.cell.severity === 'red' },
        { protection: this.cell.severity === 'protection' },
      ]
    },
  },
}
</script>
