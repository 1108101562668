<template>
  <ClickableCardWrapperStyled>
    <router-link :to="card.to" :key="card.id" :class="{ selected: card.isActive, disabled: card.isDisabled }">
      <header><LabelAtom :label="card.label" :isDisabled="card.isDisabled" /></header>
      <main>
        <ValueAtom :isLoading="card.isLoading" :value="card.count" :isDisabled="card.isDisabled" />
        <slot name="badge" />
      </main>
    </router-link>
    <div class="arrow" v-if="card.isActive"></div>
  </ClickableCardWrapperStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { buttonReset } from '@styles/mixins'
import ValueAtom from '@/components/Atomic/Atoms/ValueAtom'
import LabelAtom from '@/components/Atomic/Atoms/LabelAtom'

const ClickableCardWrapperStyled = styled('div')`
  display: grid;
  grid-template-rows: 5rem 1.5rem;
  gap: 1.5rem;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  > a {
    ${props => buttonReset(props)}
    display: grid;
    grid-template-rows: min-content min-content;
    grid-template-columns: minmax(160px, min-content);
    background: ${props => props.theme.colors.solidBG};
    justify-self: flex-start;
    padding: 0.25rem;
    border-radius: 0.75rem;
    color: ${props => props.theme.colors.navFontNormal} !important;
    box-shadow: 0px 3px 6px ${({ theme }) => chroma(theme.colors.black).alpha(0.32).css()};
    > header {
      display: flex;
      align-items: center;
      padding: 2px;
    }
    > main {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 3rem;
      grid-gap: 0.5rem;
      align-items: center;
      border-top: 1px solid #d9d9d9;
      padding: 2px 0.25rem 0.25rem 0.25rem;
      > .loader {
        justify-self: center;
      }
    }

    &:hover {
      color: ${props => props.theme.colors.primaryActive} !important;
    }
    &.selected {
      transform: translateY(0px);
      color: ${props => props.theme.colors.primary} !important;
    }
    &.disabled {
      pointer-events: none;
      box-shadow: none;
      color: ${props => props.theme.colors.muted} !important;
    }
  }
  .arrow {
    width: 3rem;
    height: 1.5rem;
    &::after {
      content: '';
      width: 0rem;
      height: 0rem;
      display: flex;
      border-bottom: 1.5rem solid ${props => props.theme.colors.solidBG};
      border-left: 1.5rem solid transparent;
      border-right: 1.5rem solid transparent;
    }
  }
`

export default {
  components: {
    LabelAtom,
    ValueAtom,
    ClickableCardWrapperStyled,
  },
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
}
</script>
