<template>
  <LoadingDots class="loader" v-if="isLoading" />
  <ValueStyled v-else :class="[isDisabled ? 'disabled' : '', fontSize]">{{ tweenedNumber.value }}</ValueStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import anime from 'animejs'
import { LoadingDots } from '@common/components'

const ValueStyled = styled('div')`
  text-transform: uppercase;
  text-align: right;
  font-weight: 600;
  font-size: 36px;
  &.size-m {
    font-size: 30px;
  }
  &.size-s {
    font-size: 24px;
  }
  &.disabled {
    opacity: 0.5;
  }
`

export default {
  components: {
    LoadingDots,
    ValueStyled,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tweenedNumber: {
        value: 0,
      },
    }
  },
  computed: {
    fontSize() {
      if (this.value > 9999) {
        return 'size-s'
      }
      if (this.value > 999) {
        return 'size-m'
      }
      return 'size-l'
    },
  },
  watch: {
    value: {
      handler(current, before) {
        const from = before ?? 0
        anime({
          targets: this.tweenedNumber,
          value: [from, current],
          round: 1,
          duration: 1000,
          easing: 'easeInOutExpo',
        })
      },
      immediate: true,
    },
  },
}
</script>
