<template>
  <PlainCellStyled class="cell" :isMobile="isMobile" :panel="cell.panel">
    <span :title="cell.label"> {{ cell.label }} </span>
  </PlainCellStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const PlainCellStyled = styled('div')`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  overflow: hidden;
  > span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  @media (min-width: 1024px) {
    padding-left: 0;
    ${({ isMobile, panel }) => !isMobile && !panel && `padding-left: 3rem`};
  }
`

export default {
  props: {
    cell: {
      type: Object,
      required: true,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    panel: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PlainCellStyled,
  },
}
</script>
