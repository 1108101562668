<template>
  <MaintenancePanelMolecule :title="$t('maintenance.insightsBySeverity')" :badge="$t('maintenance.badgeText', { x: badgeCount })">
    <template v-slot:main>
      <div v-if="error" class="error">
        {{ error }}
      </div>
      <BlockChartInteractionMolecule v-else>
        <ScatterChartAtom :isLoading="$apollo.loading" :option="scatterdChartOptions" />
      </BlockChartInteractionMolecule>
    </template>
  </MaintenancePanelMolecule>
</template>

<script>
import { DateTime, Duration } from 'luxon'
import units from '@/utils/units'

import { timeFrameParamGenerator, getColorBySeverity } from '@/utils/maintenance/healthUtils'

import BlockChartInteractionMolecule from '@/components/Atomic/Molecules/BlockChartInteractionMolecule.vue'
import ScatterChartAtom from '@/components/Atomic/Atoms/ScatterChartAtom'
import MaintenancePanelMolecule from '@/components/Atomic/Molecules/Maintenance/MaintenancePanelMolecule'
import { getAssetDimensionNameByLocale } from '@common/utils/src'

import ASSET_DIMENSION_DATA_QUERY from '#/graphql/assetDimensions/data.gql'
import ASSET_DIMENSION_QUERY from '#/graphql/assetDimensions/assetDimension.gql'
import INSIGHTS_BY_SEVERITY_QUERY from '#/graphql/operations/maintenance/insightsBySeverityQuery.gql'

export default {
  inject: ['theme', 'uiSettings'],
  props: {
    assetId: {
      type: String,
      required: true,
    },
    durationTag: {
      type: String,
      required: true,
    },
  },
  components: {
    MaintenancePanelMolecule,
    BlockChartInteractionMolecule,
    ScatterChartAtom,
  },
  data() {
    return {
      option: {},
      operationTime: [],
      assetIssues: [],
      assetDimension: null,
      scatterData: [],
      error: null,
      highestCount: 0,
    }
  },
  computed: {
    locale() {
      return (this.uiSettings?.dates ?? 'DE_DE').toLowerCase().replace('_', '-')
    },
    timeFrame() {
      return timeFrameParamGenerator(this.durationTag)
    },
    badgeCount() {
      return this.timeFrame.noOfDays
    },
    label() {
      const kpiLocale = this.locale.toUpperCase().slice(0, 2)
      return this.assetDimension?.nameTranslations
        ? getAssetDimensionNameByLocale(this.assetDimension?.nameTranslations, kpiLocale)
        : this.assetDimension?.name ?? ''
    },
    scatterdChartOptions() {
      return {
        legend: {
          data: [this.label],
          textStyle: { color: this.theme.colors.textActivePrimary },
        },
        tooltip: {
          position: 'top',
          formatter: params => {
            return (
              params.value[2] +
              ' issues on ' +
              this.daysInTimeframe[params.value[0]]?.label +
              ' of ' +
              this.insightsByOccuranceYaxis[params.value[1]]
            )
          },
        },
        xAxis: {
          type: 'category',
          axisLine: {
            show: true,
            lineStyle: {
              color: this.theme.isDark ? this.theme.colors.muted : '#aaa',
            },
          },
          axisTick: {
            lineStyle: {
              color: this.theme.isDark ? this.theme.colors.muted : '#aaa',
            },
          },
          data: this.daysInTimeframe.map(d => d.label),
          splitLine: {
            show: false,
            interval: 0,
            lineStyle: {
              color: '#aaa',
            },
          },
          axisLabel: {
            color: this.theme.colors.textActivePrimary,
            align: 'center',
          },
        },
        yAxis: [
          {
            type: 'category',
            data: this.insightsByOccuranceYaxis,
            name: this.$tc('severity', 1).slice(0, 1).toUpperCase() + this.$tc('severity', 1).slice(1),
            nameTextStyle: {
              color: this.theme.colors.textActivePrimary,
              align: 'right',
              padding: [0, 8],
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: this.theme.isDark ? this.theme.colors.muted : '#aaa',
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dotted',
                color: this.theme.isDark ? this.theme.colors.muted : '#aaa',
              },
            },
            axisLabel: {
              color: this.theme.isDark ? this.theme.colors.muted : '#aaa',
            },
          },
          {
            type: 'value',
            name: this.label,
            axisLabel: {
              color: this.theme.colors.textActivePrimary,
              formatter: '{value}' + (this.assetDimension ? this.assetDimension.physicalUnitUIMetric : null),
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: this.theme.colors.atomic.mildgrey,
              },
            },
            nameTextStyle: {
              color: this.theme.colors.textActivePrimary,
              align: 'left',
              padding: [0, 8],
            },
          },
        ],
        series: this.insightsByOccuranceSeries,
      }
    },
    daysInTimeframe() {
      const interval = this.timeFrame?.interval
      if (!interval) {
        return []
      }
      const ivs = interval.splitBy(Duration.fromISO('P1D')).map(day => {
        return {
          startUTC: day.start.toUTC().toISO(),
          interval: day,
          label: day.start.setLocale(this.locale).toLocaleString({ day: 'numeric', month: 'short' }),
        }
      })
      return ivs
    },
    possibleSeverities() {
      return ['Protection', 'Malfunction', 'Yellow', 'Red', 'Priority']
    },
    insightsByOccuranceYaxis() {
      return this.possibleSeverities.map(s => this.$t('severityCodes.' + s.toLowerCase()).toUpperCase())
    },
    insightsByOccuranceSeries() {
      return [
        {
          name: 'Insights',
          type: 'scatter',
          symbolSize: dataItem => {
            return dataItem[3]
          },
          itemStyle: {
            color: dataItem => {
              const severityIdx = dataItem.data[1]
              const color = getColorBySeverity(this.possibleSeverities[severityIdx], this.theme)
              return color
            },
          },
          data: this.scatterData,
        },
        {
          name: this.label,
          type: 'line',
          step: 'middle',
          symbol: 'none',
          yAxisIndex: 1,
          data: this.operationTimeSeries,
        },
      ]
    },
    selectedTimeframe() {
      if (!this.timeFrame) {
        return null
      }
      return {
        start: this.timeFrame.start,
        end: this.timeFrame.end,
        timezone: this.timeFrame.timezone,
        granularity: this.timeFrame.granularity,
        shifts: [],
      }
    },
    operationTimeSeries() {
      const operationTimeToDay = this.daysInTimeframe.reduce((acc, day) => {
        const operationTimeOfDay = this.operationTime.find(data => day.interval.contains(DateTime.fromISO(data.time)))
        if (!operationTimeOfDay) {
          acc.push(0)
        } else {
          const unitSI = this.assetDimension ? this.assetDimension.physicalUnitSI : null
          const unitUIMetric = this.assetDimension ? this.assetDimension.physicalUnitUIMetric : null
          const convertedValue = units(operationTimeOfDay.floatValue, unitSI, unitUIMetric, 2, false, false, false, null, null, true)
          acc.push(convertedValue)
        }
        return acc
      }, [])
      return operationTimeToDay
    },
  },
  apollo: {
    assetIssuesCount: {
      query: INSIGHTS_BY_SEVERITY_QUERY,
      variables() {
        return {
          where: {
            timeframe: this.selectedTimeframe,
            assetIds: [this.assetId],
            groupBy: ['SEVERITIES', 'TIME'],
          },
        }
      },
      manual: true,
      result({ data, error }) {
        this.highestCount = 0
        if (error) {
          this.scatterData = []
          this.highestCount = 0
          return
        }
        if (data?.insightsBySeverity?.length > 0) {
          try {
            // final data should be an array of arrays where
            // each item in the outer array has three values:
            // First value is the position on xAxis,
            // second value is the position on yAxis,
            // third value is the count,
            // fourth value is the size of the item
            //
            // to optimise for speed we use pre-built maps
            // where the keys correspond to the shape
            // of the data that we expect from the GraphQL query
            const possibleSeverities = this.possibleSeverities

            const map = new Map(
              this.daysInTimeframe.map((day, xCoord) => [
                day.startUTC,
                {
                  xCoord,
                  severities: new Map(
                    possibleSeverities.map((severity, yCoord) => [
                      severity,
                      {
                        count: 0,
                        yCoord,
                      },
                    ]),
                  ),
                },
              ]),
            )
            const scatterData = []

            const scatterDataMap = data?.insightsBySeverity.reduce((map, issueCountItem) => {
              // check if data is complete,
              // because time and severities
              // have to be nullable in schema
              if (!issueCountItem.time) {
                throw new Error(`Missing '.time' in issueCountItem`)
              }
              if ((issueCountItem?.severities?.length ?? 0) < 1) {
                throw new Error(`Missing '.severities' in issueCountItem`)
              }

              const day = map.get(new Date(issueCountItem?.time)?.toISOString())

              issueCountItem?.severities.forEach(severity => {
                day.severities.get(severity).count += issueCountItem.count
                this.highestCount = Math.max(day?.severities.get(severity).count, this.highestCount)
              })
              return map
            }, map)
            // eslint-disable-next-line no-unused-vars
            for (const [_key, day] of scatterDataMap) {
              // eslint-disable-next-line no-unused-vars
              for (const [__key, severity] of day.severities) {
                const ratio = severity.count === 0 ? 0 : 1 - severity.count / this.highestCount
                const size = severity.count === 0 ? 0 : 30 - 20 * ratio
                scatterData.push([day.xCoord, severity.yCoord, severity.count, size])
              }
            }
            this.error = null
            this.scatterData = scatterData
          } catch (err) {
            this.error = err
            this.scatterData = []
            this.highestCount = 0
          }
        }
      },
    },
    operationTime: {
      query: ASSET_DIMENSION_DATA_QUERY,
      variables() {
        return {
          where: {
            assetDimension: {
              name: 'operation_time',
            },
            timeframe: this.selectedTimeframe,
            assets: {
              id_in: [this.assetId],
            },
          },
        }
      },
      update({ assetDimensionData }) {
        return assetDimensionData.aggrByTimebuckets
      },
      skip() {
        return !this.selectedTimeframe || !this.assetId
      },
    },
    assetDimension: {
      query: ASSET_DIMENSION_QUERY,
      variables() {
        return {
          where: {
            name: 'operation_time',
          },
        }
      },
    },
  },
}
</script>
