var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SeverityLampsStyled', [_c('SeverityLampAtom', {
    class: {
      priority: _vm.priorityEnabled,
      active: _vm.activeIssues && _vm.activeIssues.includes('Priority')
    }
  }), _c('SeverityLampAtom', {
    class: {
      red: _vm.errorEnabled,
      active: _vm.activeIssues && _vm.activeIssues.includes('Red')
    }
  }), _c('SeverityLampAtom', {
    class: {
      amber: _vm.warningEnabled,
      active: _vm.activeIssues && _vm.activeIssues.includes('Yellow')
    }
  }), _c('SeverityLampAtom', {
    class: {
      malfunction: _vm.malfunctionEnabled,
      active: _vm.activeIssues && _vm.activeIssues.includes('Malfunction')
    }
  }), _c('SeverityLampAtom', {
    class: {
      protection: _vm.protectionEnabled,
      active: _vm.activeIssues && _vm.activeIssues.includes('Protection')
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }