<template>
  <LineChartStyled>
    <VChart ref="chart" :theme="selectedTheme" :option="option" :updateOptions="{ notMerge: true }" autoresize />
  </LineChartStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { use } from 'echarts/core'
import { LinearGradient } from 'echarts/lib/util/graphic'
import { CanvasRenderer } from 'echarts/renderers'
import { LineChart } from 'echarts/charts'
import { GraphicComponent, GridComponent, VisualMapComponent } from 'echarts/components'
import VChart from 'vue-echarts'
import { DarkEChartTheme, LightEChartTheme } from '@styles/themes'

use([CanvasRenderer, LineChart, GraphicComponent, GridComponent, VisualMapComponent, LinearGradient])

const LineChartStyled = styled('div')`
  display: flex;
  position: relative;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export default {
  inject: ['theme'],
  props: {
    option: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
    },
  },
  components: {
    LineChartStyled,
    VChart,
  },
  watch: {
    isLoading: {
      handler() {
        this.handleLoadingState()
      },
      immediate: true,
    },
  },
  mounted() {
    this.handleLoadingState()
  },
  computed: {
    selectedTheme() {
      return this.theme.isDark ? DarkEChartTheme : LightEChartTheme
    },
  },
  methods: {
    handleLoadingState() {
      const isLoading = this.isLoading
      if (this.isLoading === undefined) {
        return
      }
      const eChart = this.$refs.chart?.chart
      if (eChart) {
        if (isLoading) {
          eChart.showLoading()
        } else {
          eChart.hideLoading()
        }
      }
    },
  },
}
</script>
