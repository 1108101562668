var render = function () {
  var _this$$route, _this$$route$params, _this$$route2, _this$$route2$params, _this$$route3, _this$$route3$params, _this$$route4, _this$$route4$params, _this$$route5, _this$$route5$params, _this$$route6, _this$$route6$params, _this$$route7, _this$$route7$params;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetHealthStatisticsStyled', [_c('div', {
    staticClass: "group span-2"
  }, [_c('ClickableCardsOrganism', {
    attrs: {
      "assetId": (_this$$route = this.$route) === null || _this$$route === void 0 ? void 0 : (_this$$route$params = _this$$route.params) === null || _this$$route$params === void 0 ? void 0 : _this$$route$params.id,
      "selectedCardId": this.selectedCardId,
      "defaultCardId": _vm.defaultCardId
    }
  }), _c('InsightsBySeverityOrganism', {
    staticClass: "span-2",
    attrs: {
      "assetId": (_this$$route2 = this.$route) === null || _this$$route2 === void 0 ? void 0 : (_this$$route2$params = _this$$route2.params) === null || _this$$route2$params === void 0 ? void 0 : _this$$route2$params.id,
      "durationTag": this.selectedCardId
    }
  })], 1), _c('HourMeterOrganism', {
    attrs: {
      "assetId": (_this$$route3 = this.$route) === null || _this$$route3 === void 0 ? void 0 : (_this$$route3$params = _this$$route3.params) === null || _this$$route3$params === void 0 ? void 0 : _this$$route3$params.id,
      "durationTag": this.selectedCardId
    }
  }), _c('AssetActivityOrganism', {
    attrs: {
      "assetId": (_this$$route4 = this.$route) === null || _this$$route4 === void 0 ? void 0 : (_this$$route4$params = _this$$route4.params) === null || _this$$route4$params === void 0 ? void 0 : _this$$route4$params.id,
      "durationTag": this.selectedCardId
    }
  }), _c('InsightsByOccurrenceOrganism', {
    staticClass: "span-2",
    attrs: {
      "assetId": (_this$$route5 = this.$route) === null || _this$$route5 === void 0 ? void 0 : (_this$$route5$params = _this$$route5.params) === null || _this$$route5$params === void 0 ? void 0 : _this$$route5$params.id,
      "durationTag": this.selectedCardId
    }
  }), _c('InsightsByCountOrganism', {
    staticClass: "span-2",
    attrs: {
      "assetId": (_this$$route6 = this.$route) === null || _this$$route6 === void 0 ? void 0 : (_this$$route6$params = _this$$route6.params) === null || _this$$route6$params === void 0 ? void 0 : _this$$route6$params.id,
      "durationTag": this.selectedCardId
    }
  }), _c('InsightsByDurationOrganism', {
    staticClass: "span-2",
    attrs: {
      "assetId": (_this$$route7 = this.$route) === null || _this$$route7 === void 0 ? void 0 : (_this$$route7$params = _this$$route7.params) === null || _this$$route7$params === void 0 ? void 0 : _this$$route7$params.id,
      "durationTag": this.selectedCardId
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }