<template>
  <LabelStyled :class="{ disabled: isDisabled }">{{ label }}</LabelStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const LabelStyled = styled('div')`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  &.disabled {
    opacity: 0.5;
  }
`

export default {
  components: {
    LabelStyled,
  },
  props: {
    label: {
      type: String,
      requirec: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
}
</script>
