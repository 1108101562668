var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ClickableCardWrapperStyled', [_c('router-link', {
    key: _vm.card.id,
    class: {
      selected: _vm.card.isActive,
      disabled: _vm.card.isDisabled
    },
    attrs: {
      "to": _vm.card.to
    }
  }, [_c('header', [_c('LabelAtom', {
    attrs: {
      "label": _vm.card.label,
      "isDisabled": _vm.card.isDisabled
    }
  })], 1), _c('main', [_c('ValueAtom', {
    attrs: {
      "isLoading": _vm.card.isLoading,
      "value": _vm.card.count,
      "isDisabled": _vm.card.isDisabled
    }
  }), _vm._t("badge")], 2)]), _vm.card.isActive ? _c('div', {
    staticClass: "arrow"
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }