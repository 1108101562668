var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetSeverityWrapperStyled', [_c('AssetSeverityStyled', [_c('SeverityLampsMolecule', {
    attrs: {
      "severities": _vm.cell.severities,
      "activeIssues": _vm.cell.activeIssues
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }