var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ClickableCardsMolecule', {
    attrs: {
      "cards": _vm.cards,
      "isLoading": _vm.$apollo.queries.assetIssueCounts.loading
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }