var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isLoading ? _c('LoadingDots', {
    staticClass: "loader"
  }) : _c('ValueStyled', {
    class: [_vm.isDisabled ? 'disabled' : '', _vm.fontSize]
  }, [_vm._v(_vm._s(_vm.tweenedNumber.value))]);
}
var staticRenderFns = []

export { render, staticRenderFns }