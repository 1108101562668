<template>
  <MaintenancePanelMolecule :title="$t('maintenance.insightsByOccurrence')" :badge="$t('maintenance.badgeText', { x: badgeCount })">
    <template v-slot:main>
      <div v-if="error" class="error">
        {{ error }}
      </div>
      <BlockChartInteractionMolecule v-else>
        <ScatterChartAtom :isLoading="$apollo.loading" :option="scatterdChartOptions" :customToolTipText="codeDesignationMapping" />
      </BlockChartInteractionMolecule>
    </template>
  </MaintenancePanelMolecule>
</template>

<script>
import { DateTime, Duration } from 'luxon'
import units from '@/utils/units'

import { timeFrameParamGenerator, getColorBySeverity } from '@/utils/maintenance/healthUtils'

import ScatterChartAtom from '@/components/Atomic/Atoms/ScatterChartAtom'
import BlockChartInteractionMolecule from '@/components/Atomic/Molecules/BlockChartInteractionMolecule.vue'
import MaintenancePanelMolecule from '@/components/Atomic/Molecules/Maintenance/MaintenancePanelMolecule'
import { getAssetDimensionNameByLocale } from '@common/utils/src'

import ASSET_DIMENSION_DATA_QUERY from '#/graphql/assetDimensions/data.gql'
import ASSET_DIMENSION_QUERY from '#/graphql/assetDimensions/assetDimension.gql'
import INSIGHTS_BY_OCCURRANCE_QUERY from '#/graphql/operations/maintenance/insightsByOccurranceQuery.gql'

export default {
  inject: ['theme', 'uiSettings'],
  props: {
    assetId: {
      type: String,
      required: true,
    },
    durationTag: {
      type: String,
      required: true,
    },
  },
  components: {
    MaintenancePanelMolecule,
    BlockChartInteractionMolecule,
    ScatterChartAtom,
  },
  data() {
    return {
      option: {},
      operationTime: [],
      assetIssues: [],
      assetDimension: null,
      scatterData: [],
      error: null,
      highestCount: 0,
      codeDesignationMapping: [],
    }
  },
  computed: {
    locale() {
      return (this.uiSettings?.dates ?? 'DE_DE').toLowerCase().replace('_', '-')
    },
    timeFrame() {
      return timeFrameParamGenerator(this.durationTag)
    },
    badgeCount() {
      return this.timeFrame.noOfDays
    },
    label() {
      const kpiLocale = this.locale.toUpperCase().slice(0, 2)
      return this.assetDimension?.nameTranslations
        ? getAssetDimensionNameByLocale(this.assetDimension?.nameTranslations, kpiLocale)
        : this.assetDimension?.name ?? ''
    },
    scatterdChartOptions() {
      return {
        legend: {
          data: [this.label],
          textStyle: { color: this.theme.colors.textActivePrimary },
        },
        dataZoom: [
          {
            type: 'slider',
            yAxisIndex: [0],
            startValue: 0,
            endValue: 9,
            minValueSpan: 10,
            maxValueSpan: 10,
            zoomLock: true,
            left: 20,
          },
          {
            type: 'inside',
            filterMode: 'weakFilter',
            yAxisIndex: [0],
          },
        ],
        tooltip: {
          position: 'top',
          formatter: params => {
            return params.value[2] + ' issues on ' + this.daysInTimeframe[params.value[0]]?.label + ' of ' + this.yAxisData[params.value[1]]
          },
        },
        xAxis: {
          type: 'category',
          axisLine: {
            show: true,
            lineStyle: {
              color: this.theme.isDark ? this.theme.colors.muted : '#aaa',
            },
          },
          axisTick: {
            lineStyle: {
              color: this.theme.isDark ? this.theme.colors.muted : '#aaa',
            },
          },
          data: this.daysInTimeframe.map(d => d.label),
          splitLine: {
            show: false,
            interval: 0,
            lineStyle: {
              color: '#aaa',
            },
          },
          axisLabel: {
            color: this.theme.colors.textActivePrimary,
            align: 'center',
          },
        },
        yAxis: [
          {
            triggerEvent: true,
            type: 'category',
            data: this.yAxisData,
            name: this.$tc('code', 1).slice(0, 1).toUpperCase() + this.$tc('code', 1).slice(1),
            nameTextStyle: {
              color: this.theme.colors.textActivePrimary,
              align: 'right',
              padding: [0, 8],
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: this.theme.isDark ? this.theme.colors.muted : '#aaa',
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dotted',
                color: this.theme.isDark ? this.theme.colors.muted : '#aaa',
              },
            },
            axisLabel: {
              color: this.theme.isDark ? this.theme.colors.muted : '#aaa',
            },
          },
          {
            type: 'value',
            name: this.label,
            axisLabel: {
              color: this.theme.colors.textActivePrimary,
              formatter: '{value}' + (this.assetDimension ? this.assetDimension.physicalUnitUIMetric : null),
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: this.theme.colors.atomic.mildgrey,
              },
            },
            nameTextStyle: {
              color: this.theme.colors.textActivePrimary,
              align: 'left',
              padding: [0, 8],
            },
          },
        ],
        series: this.insightsByOccuranceSeries,
      }
    },
    daysInTimeframe() {
      const interval = this.timeFrame?.interval
      if (!interval) {
        return []
      }
      const ivs = interval.splitBy(Duration.fromISO('P1D')).map(day => {
        return {
          startUTC: day.start.toUTC().toISO(),
          interval: day,
          label: day.start.setLocale(this.locale).toLocaleString({ day: 'numeric', month: 'short' }),
        }
      })
      return ivs
    },
    possibleSeverities() {
      return ['Protection', 'Malfunction', 'Yellow', 'Red', 'Priority']
    },
    insightsByOccuranceSeries() {
      return [
        {
          name: 'Insights',
          type: 'scatter',
          symbolSize: dataItem => {
            return dataItem[3]
          },
          itemStyle: {
            color: dataItem => {
              const severityIdx = dataItem.data[4]
              const severity = this.possibleSeverities[severityIdx]
              const color = getColorBySeverity(severity, this.theme)
              return color
            },
          },
          data: this.scatterData,
        },
        {
          name: this.label,
          type: 'line',
          step: 'middle',
          symbol: 'none',
          yAxisIndex: 1,
          data: this.operationTimeSeries,
        },
      ]
    },
    selectedTimeframe() {
      if (!this.timeFrame) {
        return null
      }
      return {
        start: this.timeFrame.start,
        end: this.timeFrame.end,
        timezone: this.timeFrame.timezone,
        granularity: this.timeFrame.granularity,
        shifts: [],
      }
    },
    operationTimeSeries() {
      const operationTimeToDay = this.daysInTimeframe.reduce((acc, day) => {
        const operationTimeOfDay = this.operationTime.find(data => day.interval.contains(DateTime.fromISO(data.time)))
        if (!operationTimeOfDay) {
          acc.push(0)
        } else {
          const unitSI = this.assetDimension ? this.assetDimension.physicalUnitSI : null
          const unitUIMetric = this.assetDimension ? this.assetDimension.physicalUnitUIMetric : null
          const convertedValue = units(operationTimeOfDay.floatValue, unitSI, unitUIMetric, 2, false, false, false, null, null, true)
          acc.push(convertedValue)
        }
        return acc
      }, [])
      return operationTimeToDay
    },
  },
  apollo: {
    assetIssuesCount: {
      query: INSIGHTS_BY_OCCURRANCE_QUERY,
      variables() {
        return {
          where: {
            timeframe: this.selectedTimeframe,
            assetIds: [this.assetId],
            groupBy: ['HIGHEST_SEVERITY', 'TIME', 'CODE', 'LABEL'],
          },
        }
      },
      manual: true,
      result({ data, error }) {
        this.highestCount = 0
        if (error) {
          this.error = error
          this.scatterData = []
          this.yAxisData = []
          this.highestCount = 0
          return
        }
        if (data?.insightsByOccurrance.length > 0) {
          try {
            // final data should be an array of arrays where
            // each item in the outer array has three values:
            // First value is the position on xAxis,
            // second value is the position on yAxis,
            // third value is the count,
            // fourth value is the size of the item
            // fifth value is the highest severity rank
            //
            // to optimise for speed we use pre-built maps
            // where the keys correspond to the shape
            // of the data that we expect from the GraphQL query
            // const possibleSeverities = this.possibleSeverities

            const map = new Map(
              this.daysInTimeframe.map((day, xCoord) => [
                day.startUTC,
                {
                  xCoord,
                  codes: new Map(),
                },
              ]),
            )
            const severityRanks = new Map(this.possibleSeverities.map((severity, i) => [severity, i]))
            const issueCodesOccurred = new Set()

            const scatterData = []

            const scatterDataMap = data.insightsByOccurrance.reduce((map, issueCountItem) => {
              // check if data is complete,
              // because time and severities
              // have to be nullable in schema
              if (!issueCountItem.time) {
                throw new Error(`Missing '.time' in issueCountItem`)
              }
              if (!issueCountItem.highestSeverity) {
                throw new Error(`Missing '.highestSeverity' in issueCountItem`)
              }
              if (!issueCountItem.code) {
                throw new Error(`Missing '.code' in issueCountItem`)
              }
              const day = map.get(new Date(issueCountItem?.time)?.toISOString())
              const rank = severityRanks.get(issueCountItem.highestSeverity)
              if (day.codes.has(issueCountItem.code)) {
                const item = day.codes.get(issueCountItem.code)
                item.count += issueCountItem.count
                item.highestSeverityRank = Math.max(item.highestSeverityRank, rank)
              } else {
                issueCodesOccurred.add(issueCountItem.code)
                day.codes.set(issueCountItem.code, {
                  count: issueCountItem.count,
                  highestSeverityRank: rank,
                })
              }
              this.highestCount = Math.max(day.codes.get(issueCountItem.code).count, this.highestCount)
              return map
            }, map)

            const issueCodesSorted = Array.from(issueCodesOccurred.values()).sort((a, b) => {
              const [spnAStr, fmiAStr] = a.split('-')
              const [spnBStr, fmiBStr] = b.split('-')
              const spnA = parseInt(spnAStr)
              const fmiA = parseInt(fmiAStr ?? 0)
              const spnB = parseInt(spnBStr)
              const fmiB = parseInt(fmiBStr ?? 0)
              if (spnA === spnB) {
                return fmiA - fmiB
              }
              return spnA - spnB
            })
            const issueCodesWithYCoord = new Map(issueCodesSorted.map((m, i) => [m, i]))

            // eslint-disable-next-line no-unused-vars
            for (const [_key, day] of scatterDataMap) {
              // eslint-disable-next-line no-unused-vars
              for (const [code, item] of day.codes) {
                const ratio = item.count === 0 ? 0 : 1 - item.count / this.highestCount
                const size = item.count === 0 ? 0 : 30 - 20 * ratio
                const yCoord = issueCodesWithYCoord.get(code)
                scatterData.push([day.xCoord, yCoord, item.count, size, item.highestSeverityRank])
              }
            }
            this.error = null
            this.scatterData = scatterData
            this.yAxisData = issueCodesSorted
            this.codeDesignationMapping = data.insightsByOccurrance.map(val => {
              return {
                code: val.code,
                label: val.label,
              }
            })
          } catch (err) {
            this.error = err
            this.scatterData = []
            this.yAxisData = []
            this.highestCount = 0
          }
        }
      },
    },
    operationTime: {
      query: ASSET_DIMENSION_DATA_QUERY,
      variables() {
        return {
          where: {
            assetDimension: {
              name: 'operation_time',
            },
            timeframe: this.selectedTimeframe,
            assets: {
              id_in: [this.assetId],
            },
          },
        }
      },
      update({ assetDimensionData }) {
        return assetDimensionData.aggrByTimebuckets
      },
      skip() {
        return !this.selectedTimeframe || !this.assetId
      },
    },
    assetDimension: {
      query: ASSET_DIMENSION_QUERY,
      variables() {
        return {
          where: {
            name: 'operation_time',
          },
        }
      },
    },
  },
}
</script>
