var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ScrollableWrapperStyled', {
    class: {
      'has-scroll': _vm.hasScroll
    }
  }, [_c('ClickableCardsMoleculeStyled', {
    ref: "cards",
    class: {
      'has-scroll': _vm.hasScroll
    }
  }, [_vm._l(_vm.cards, function (card) {
    return [_c('ClickableCardMolecule', {
      key: card.id,
      ref: card.id,
      refInFor: true,
      attrs: {
        "card": card
      }
    }, [_c('div', {
      attrs: {
        "slot": "badge"
      },
      slot: "badge"
    }, [_c('BadgeAtom', {
      attrs: {
        "isDisabled": card.isDisabled,
        "label": card.badge,
        "color": card.color
      }
    })], 1)])];
  })], 2), _c('ButtonStyleless', {
    staticClass: "nav previous",
    attrs: {
      "disabled": !_vm.previousCard || _vm.previousCard.isLoading
    },
    on: {
      "click": function click($event) {
        var _vm$previousCard;
        return _vm.$emit('set-selected-card', (_vm$previousCard = _vm.previousCard) === null || _vm$previousCard === void 0 ? void 0 : _vm$previousCard.id);
      }
    }
  }, [_c('ChevronLeftIcon')], 1), _c('ButtonStyleless', {
    staticClass: "nav next",
    attrs: {
      "disabled": !_vm.nextCard || _vm.nextCard.isLoading
    },
    on: {
      "click": function click($event) {
        var _vm$nextCard;
        return _vm.$emit('set-selected-card', (_vm$nextCard = _vm.nextCard) === null || _vm$nextCard === void 0 ? void 0 : _vm$nextCard.id);
      }
    }
  }, [_c('ChevronRightIcon')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }