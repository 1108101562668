var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ChartInteractionWrapperStyled', {
    directives: [{
      name: "away",
      rawName: "v-away",
      value: _vm.away,
      expression: "away"
    }]
  }, [_vm._t("default"), _c('ChartInteractionBlockerStyled', {
    class: {
      block: _vm.blockInteraction
    },
    on: {
      "click": function click($event) {
        _vm.blockInteraction = false;
      }
    }
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.$t('messages.clickToInteract')) + " ")])])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }