<template>
  <PieChart :isLoading="isLoading" :displayLegend="true" :option="option" />
</template>

<script>
import PieChart from '@/components/Atomic/Atoms/PieChart'
import localesMixin from '@/mixins/locales'
import { getAssetDimensionNameByLocale } from '@common/utils/src'

export default {
  inject: ['theme', 'uiSettings'],
  mixins: [localesMixin],
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    activityDataSet: {
      type: Array,
      required: true,
    },
  },
  components: {
    PieChart,
  },
  computed: {
    locale() {
      const userLocale = this.uiSettings?.dates ?? 'DE_DE'
      const replacedDates = userLocale.replace('_', '-')
      return replacedDates.slice(0, 2)
    },
    colors() {
      return [this.theme.colors.atomic.avatar.grassGreen, this.theme.colors.atomic.avatar.darkOrange]
    },
    labels() {
      return this.activityDataSet.map(({ nameTranslations, name }) =>
        nameTranslations ? getAssetDimensionNameByLocale(nameTranslations, this.locale) : name,
      )
    },
    dataSets() {
      const total = this.activityDataSet.reduce((a, b) => a + b['total'], 0)
      return this.activityDataSet.map(dimension => {
        const name = dimension.nameTranslations ? getAssetDimensionNameByLocale(dimension.nameTranslations, this.locale) : dimension.name
        const unitSI = dimension.physicalUnitSI
        const unitUIMetric = dimension.physicalUnitUIMetric
        const unitUIImperial = dimension.physicalUnitUIImperial
        const unitUI = unitUIImperial && this.selectedUIUnitSystem === 'IMPERIAL' ? unitUIImperial : unitUIMetric
        const unit = unitUI ? unitUI : unitSI
        const value = this.convUnit(dimension.total, unitSI, unitUI, 2, false)
        const totalFormatted = this.convUnit(total, unitSI, unitUI, 2, false)
        return {
          name,
          value,
          unit: unit ? unit : '',
          tooltipValue: this.numberLocalized(value),
          tooltipUnit: unit,
          percentage: (value / totalFormatted) * 100,
        }
      })
    },
    piechartRadius() {
      return {
        radius: ['80%'],
        center: ['30%', '50%'],
      }
    },
    option() {
      return {
        legend: {
          selectedMode: false,
          type: 'scroll',
          orient: this.isMobile ? 'horizontal' : 'vertical',
          top: 10,
          right: '10%',
          bottom: 10,
          data: this.labels,
          animation: true,
          textStyle: {
            color: this.theme.colors.textActivePrimary,
          },
          pageIconColor: this.theme.colors.primaryActive,
          pageTextStyle: {
            color: this.theme.colors.textActivePrimary,
          },
          formatter: name => {
            const found = this.dataSets.find(f => f.name === name)
            if (found && found.value && found.unit) {
              return `${found.name}: ${found.value} ${found.unit}`
            }
            return name
          },
        },
        series: [
          {
            type: 'pie',
            ...this.piechartRadius,
            data: this.dataSets,
            itemStyle: {
              borderWidth: 2,
              borderColor: this.theme.colors.solidBG,
            },
            label: {
              show: false,
              position: 'center',
            },
            avoidLabelOverlap: false,
            color: this.colors,
            emphasis: {
              disabled: true,
            },
            animationType: 'scale',
            animationEasing: 'elasticOut',
          },
        ],
      }
    },
  },
}
</script>
