var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetCountWrapperStyled', [_vm.cell.count !== '-' || _vm.cell.count !== '- h' ? [_c('div', [_c('CountBarStyled', {
    class: _vm.classGenerator,
    attrs: {
      "percent": _vm.cell.percent
    }
  })], 1), _c('span', {
    staticClass: "count"
  }, [_vm._v(_vm._s(_vm.cell.count) + " ")])] : _c('span', [_vm._v(_vm._s(_vm.cell.count))])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }