var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MaintenancePanelMolecule', {
    attrs: {
      "title": _vm.$t('maintenance.insightsBySeverity'),
      "badge": _vm.$t('maintenance.badgeText', {
        x: _vm.badgeCount
      })
    },
    scopedSlots: _vm._u([{
      key: "main",
      fn: function fn() {
        return [_vm.error ? _c('div', {
          staticClass: "error"
        }, [_vm._v(" " + _vm._s(_vm.error) + " ")]) : _c('BlockChartInteractionMolecule', [_c('ScatterChartAtom', {
          attrs: {
            "isLoading": _vm.$apollo.loading,
            "option": _vm.scatterdChartOptions
          }
        })], 1)];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }