var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MaintenancePanelMoleculeStyled', [_c('header', [_vm.hasHeaderSlot ? _vm._t("header") : [_c('span', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.title))]), _vm.badge ? _c('BadgeAtom', {
    attrs: {
      "label": _vm.badge
    }
  }) : _vm._e()]], 2), _c('main', [_vm._t("main")], 2), _vm.hasFooterSlot ? _c('footer', [_vm._t("footer")], 2) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }