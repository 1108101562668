<template>
  <ScrollableWrapperStyled :class="{ 'has-scroll': hasScroll }">
    <ClickableCardsMoleculeStyled :class="{ 'has-scroll': hasScroll }" ref="cards">
      <template v-for="card in cards">
        <ClickableCardMolecule :ref="card.id" :card="card" :key="card.id">
          <div slot="badge">
            <BadgeAtom :isDisabled="card.isDisabled" :label="card.badge" :color="card.color" />
          </div>
        </ClickableCardMolecule>
      </template>
    </ClickableCardsMoleculeStyled>
    <ButtonStyleless
      class="nav previous"
      :disabled="!previousCard || previousCard.isLoading"
      @click="$emit('set-selected-card', previousCard?.id)"
    >
      <ChevronLeftIcon />
    </ButtonStyleless>
    <ButtonStyleless class="nav next" :disabled="!nextCard || nextCard.isLoading" @click="$emit('set-selected-card', nextCard?.id)">
      <ChevronRightIcon />
    </ButtonStyleless>
  </ScrollableWrapperStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { ChevronLeftIcon, ChevronRightIcon } from 'vue-feather-icons'
import chroma from 'chroma-js'

import resizeMixin from '@/mixins/resize'

import ButtonStyleless from '@/components/Atomic/Atoms/ButtonStyleless'
import ClickableCardMolecule from '@/components/Atomic/Molecules/ClickableCardMolecule'
import BadgeAtom from '@/components/Atomic/Atoms/BadgeAtom'

const ScrollableWrapperStyled = styled('div')`
  position: relative;
  overflow: hidden;
  > .nav {
    display: none;
    position: absolute;
    top: 0;
    align-items: center;
    height: 6rem;
    border-radius: 0.5rem;
    background: ${({ theme }) => theme.colors.solidBG};
    border: 3px solid ${({ theme }) => chroma(theme.colors.mainBG).alpha(1).css()};
    &.previous {
      left: 0;
    }
    &.next {
      right: 0;
    }
    &:disabled {
      opacity: 0.5;
      color: ${({ theme }) => theme.colors.muted};
    }
  }
  &.has-scroll {
    > .nav {
      display: flex;
    }
  }
`

const ClickableCardsMoleculeStyled = styled('div')`
  display: grid;
  width: 100%;
  overflow: auto;
  height: 8rem;
  margin: 0.5rem 0 0 0;
  grid-auto-columns: minmax(min-content, 1fr);
  grid-auto-flow: column;
  grid-template-rows: 8rem;
  grid-gap: 1rem;
  justify-items: center;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  &.has-scroll {
    > .nav {
      display: flex;
    }
    width: calc(100% - 5rem);
    padding: 0 2rem;
    margin: 0.5rem 0.5rem 0 0.5rem;
  }
`

export default {
  mixins: [resizeMixin],
  props: {
    cards: {
      type: Array,
      required: true,
    },
  },
  components: {
    ScrollableWrapperStyled,
    ClickableCardsMoleculeStyled,
    ClickableCardMolecule,
    BadgeAtom,
    ButtonStyleless,
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  data() {
    return {
      hasScroll: false,
    }
  },
  mounted() {
    this.checkHasScroll()
  },
  computed: {
    scrollPosition() {
      return this.cards.findIndex(card => card.isActive)
    },
    selectedCard() {
      return this.cards.find(card => card.isActive)
    },
    enabledCards() {
      return this.cards.filter(card => !card.isDisabled)
    },
    previousCard() {
      if (this.enabledCards.length < 2) {
        return null
      }
      const idxSelected = this.enabledCards.findIndex(card => card.isActive)
      const prev = this.enabledCards[idxSelected - 1]
      if (prev) {
        return prev ?? null
      }
      return this.enabledCards[this.enabledCards.length - 1] ?? null
    },
    nextCard() {
      if (this.enabledCards.length < 2) {
        return null
      }
      const idxSelected = this.enabledCards.findIndex(card => card.isActive)
      const next = this.enabledCards[idxSelected + 1]
      if (next) {
        return next ?? null
      }
      return this.enabledCards[0] ?? null
    },
  },
  watch: {
    scrollPosition: {
      handler() {
        this.checkScrollPosition()
      },
    },
  },
  methods: {
    handleResize() {
      this.checkHasScroll()
    },
    checkScrollPosition() {
      if (this.hasScroll) {
        const el = this.$refs[this.selectedCard?.id]?.[0]?.$el
        if (el) {
          el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
        }
      }
    },
    checkHasScroll() {
      this.hasScroll = this.$refs.cards?.scrollWidth > this.$refs.cards?.clientWidth
    },
  },
}
</script>
