var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('PieChartStyled', [_c('VChart', {
    ref: "chart",
    attrs: {
      "option": _vm.optionMapped,
      "updateOptions": {
        notMerge: true
      },
      "autoresize": ""
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }