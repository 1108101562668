import { DateTime } from 'luxon'
import { getLast14DaysInterval, getLast21DaysInterval, getLast30DaysInterval, getLast7DaysInterval } from '../time'

export const mappedTyreSeverity = (temperatureServerity, pressureThresholdSeverity) => {
  const combinedSeverity = []
  combinedSeverity.push(temperatureServerity === '' ? 'UNKNOWN' : temperatureServerity)
  combinedSeverity.push(pressureThresholdSeverity === '' ? 'UNKNOWN' : pressureThresholdSeverity)
  if (combinedSeverity.includes('UNKNOWN')) {
    return 'Unknown'
  } else if (
    combinedSeverity.includes('CRITICAL') ||
    combinedSeverity.includes('CRITICALLY_HIGH') ||
    combinedSeverity.includes('CRITICALLY_LOW')
  ) {
    return 'Red'
  } else if (combinedSeverity.includes('HIGH') || combinedSeverity.includes('LOW')) {
    return 'Yellow'
  } else {
    return 'Green'
  }
}

export const healthMapper = healthData => {
  const combinedHealth = healthData.includes('Red')
    ? 'Red'
    : healthData.includes('Yellow')
    ? 'Yellow'
    : healthData.includes('Green')
    ? 'Green'
    : 'Unknown'
  return combinedHealth
}

export const severityRanking = severity => {
  if (severity.toLowerCase() === 'priority') {
    return 6
  } else if (severity.toLowerCase() === 'red') {
    return 5
  } else if (severity.toLowerCase() === 'yellow') {
    return 4
  } else if (severity.toLowerCase() === 'malfunction') {
    return 3
  } else if (severity.toLowerCase() === 'protection') {
    return 2
  } else {
    return 1
  }
}

export const timeFrameParamGenerator = duration => {
  let interval = null
  let noOfDays = null
  switch (duration) {
    case '7d':
    case 'last7Days':
      interval = getLast7DaysInterval()
      noOfDays = 7
      break
    case '14d':
    case 'last14Days':
      interval = getLast14DaysInterval()
      noOfDays = 14
      break
    case '21d':
    case 'last21Days':
      interval = getLast21DaysInterval()
      noOfDays = 21
      break
    case '30d':
    case 'last30Days':
      interval = getLast30DaysInterval()
      noOfDays = 30
      break
  }

  const granularity = interval?.length('days') > 2 ? 'P1D' : 'PT1H'
  const start = interval.start.toUTC().toISO()
  const end = interval.end.toUTC().toISO()

  if (start && end) {
    return {
      start,
      end,
      interval,
      timezone: DateTime.local().zoneName,
      granularity,
      shifts: [],
      noOfDays,
    }
  } else {
    return null
  }
}

export const getColorBySeverity = (severity, theme) => {
  if (severity.toLowerCase() === 'priority') {
    return theme.colors.atomic.severityPriority
  } else if (severity.toLowerCase() === 'red') {
    return theme.colors.atomic.severityRed
  } else if (severity.toLowerCase() === 'yellow') {
    return theme.colors.atomic.severityAmber
  } else if (severity.toLowerCase() === 'malfunction') {
    return theme.colors.atomic.severityMalfunction
  } else if (severity.toLowerCase() === 'protection') {
    return theme.colors.atomic.severityProtection
  } else {
    throw new Error(`Could not map severity '${severity}' to a known color`)
  }
}

export const getAssetIssueHighestSeverityColor = (assetIssue, theme) => {
  if (!theme) {
    throw new Error(`Missing theme parameter.`)
  }
  const { highestSeverity } = assetIssue
  if (!highestSeverity) {
    throw new Error(`Missing 'highestSeverity' in assetIssue ${assetIssue.id}`)
  }
  return getColorBySeverity(highestSeverity, theme)
}
